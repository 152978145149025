import React from 'react'
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import owlStyle from "../../assets/styles/ModuleStyle/customOwl.module.css";

import OwlCarousel from "react-owl-carousel"
import { baseUrl } from '../../config';
import { Link } from 'react-router-dom';

export default function SpecializationOffered({ data }) {

  console.log("data", data);

  return (
    <div
      id="Specialization_Offered"
      className="uni_special_course mb-5"
    >
      <div className="title mt-5">
        <h3>Specialization Offered</h3>
      </div>
      <div className="Specialization-list mt-lg-4 mt-3">
        <OwlCarousel
          id="top_university"
          className="owl-carousel owl-theme row"
          nav
          dots={false}
          stageOuterClass={owlStyle.owlStageOuterSpec}
          navText={[
            "<i class='fa fa-chevron-left'></i>",
            "<i class='fa fa-chevron-right'></i>",
          ]}
          navContainerClass={owlStyle.containerNav}
          navClass={["navPrev", "navNext"]}
          autoplay={true}
          autoplayHoverPause={true}
        >
          {data.map((item, i) => (
            <>
              <div
                className="item col"
                data-aos="fade-in"
                data-aos-delay={100}
              >
                <div className={CrStyle["specializ-box"]}>
                  <div className={CrStyle["specializ-img"]}>
                    <img
                      className
                      src={`${baseUrl}${item?.image}`}
                      alt={item?.image}
                    />
                  </div>
                  <div className={CrStyle["spec-tital-box"]}>
                    <div className={CrStyle.title}>
                      <Link to={`/course/couse-specialization-details/${item?.name}`}>
                        {item?.name}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </OwlCarousel>
      </div>
    </div>
  )
}
