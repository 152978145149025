import React from "react";

import Style from "../../assets/styles/ModuleStyle/course.module.css";
import LogoSlider from "../Elements/LogoSlider";

export default function CourseMainModal() {
  return (
    <>
      <div
        className="modal fade connsult-wrap"
        id="modalLoginForm2"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title w-100 font-weight-bold">
                Need a Boost in your Professional Journey?
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body consult-body mx-3">
              <div className="row">
                <div className="col-lg-5">
                  <div className="consult-details">
                   <LogoSlider />
                    <div className={Style["spec-title"]}>
                      <h4>
                        On a single platform, compare the best colleges in India{" "}
                      </h4>{" "}
                    </div>
                    <div className={Style["spec-le-img"]}>
                      <img src="/image/form-img.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <h3 className="modal-title w-100 font-weight-bold">
                    {" "}
                    Make a Quick Inquiry
                  </h3>
                  <ul className="d-flex justify-content-start flex-md-row flex-sm-column list-unstyled mt-2 lead-form-lead-point">
                    <li className="mx-1">
                      <i className="uil uil-shield-check" /> Get Approved
                      University
                    </li>
                    <li className="mx-1">
                      <i className="uil uil-shield-check" /> 100% Placement
                      Assistance
                    </li>
                  </ul>
                  <div className="consult-form contact_form">
                    <form action="#" className="comment_form">
                      <div>
                        {/* <div className=form_title">Name</div>*/}
                        <input
                          name="consult-name"
                          type="text"
                          placeholder="Name"
                          className="comment_input"
                          required="required"
                        />
                      </div>
                      <div>
                        {/*  <div className=form_title">Email</div>*/}
                        <input
                          name="consult-email"
                          type="text"
                          placeholder="Email"
                          className="comment_input"
                          required="required"
                        />
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          {/* <div className=form_title">Mobile</div>*/}
                          <input
                            name="consult-mobile"
                            type="number"
                            placeholder="Mobile"
                            className="comment_input"
                            required="required"
                          />
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-2">
                          {/*  <div className=form_title">Select Course</div>*/}
                          <select
                            name="popup_select_coure"
                            placeholder="Select Course"
                            id="counter_select"
                            className="counter_input counter_options"
                          >
                            <option>Choose Course</option>
                            <option>Course</option>
                            <option>Course</option>
                            <option>Course</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        {/*<div className=form_title">Message</div>*/}
                        <textarea
                          name="consult-message"
                          placeholder="Message"
                          className="comment_input comment_textarea"
                          required="required"
                          defaultValue={""}
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="comment_button trans_200"
                        >
                          submit now
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="modal-footer d-flex justify-content-center">
               <button className=btn btn-default">Login</button>
                </div>*/}
          </div>
        </div>
      </div>
    </>
  );
}
