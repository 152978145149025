import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { apiBaseUrl } from "../config";

function Footer() {
  const footerDetails = useSelector((state) => state.header?.socialList);
  const [footerDet, setFooterDet] = useState({});
  const fetchFooterData = () => {
    fetch(`${apiBaseUrl}footer-course`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setFooterDet({ ...data });
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.error("Error fetching banner image:", error);
      });
  };
  useEffect(() => {
    fetchFooterData();
  }, []);
  return (
    <>
      {/* Footer */}
      <footer className="footer">
        <div
          className="footer_background"
          style={{ backgroundImage: "url(/image/footer_background.png)" }}
        />
        <div className="container">
          <div className="row footer_row">
            <div className="col">
              <div className="footer_content">
                <div className="row">
                  <div className="col-lg-4 footer_col">
                    {/* Footer About */}
                    <div className="footer_section footer_about">
                      <div className="footer_logo_container">
                        <Link to="#">
                          <div className="footer_logo_text">
                            About<span> UniversityGuroo</span>
                          </div>
                        </Link>
                      </div>
                      <div className="footer_about_text">
                        <p>{footerDetails?.about_footer}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 footer_col">
                    {/* Footer Contact */}
                    <div className="footer_section footer_contact">
                      <div className="footer_title">Quick Links</div>
                      <div className="footer_links_container">
                        <ul>
                          <li>
                            <Link to="#">About Us</Link>
                          </li>
                          <li>
                            <Link to="#">University Guroo Pledge</Link>
                          </li>
                          <li>
                            <Link to="#">Things You Must Know</Link>
                          </li>
                          <li>
                            <Link to="#">Why UG?</Link>
                          </li>
                          <li>
                            <Link to="#">Alumni Connect</Link>
                          </li>
                          <li>
                            <Link to="#">Lets Collaborate</Link>
                          </li>
                          <li>
                            <Link to="#">Our Policy</Link>
                          </li>
                          <li>
                            <Link to="#">Contact Us</Link>
                          </li>
                          <li>
                            <Link to="#">Virtual Counselling</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 footer_col">
                    {/* Footer links */}
                    <div className="footer_section footer_links">
                      <div className="footer_title">Social Links</div>
                      <div className="footer_social">
                        <ul>
                          <li>
                            <Link
                              to={
                                footerDetails?.social_list?.find(
                                  (item) => item.title === "Facebook"
                                ).social_link
                              }
                            >
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                footerDetails?.social_list?.find(
                                  (item) => item.title === "Instagram"
                                ).social_link
                              }
                            >
                              <i
                                className="fa fa-instagram"
                                aria-hidden="true"
                              />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                footerDetails?.social_list?.find(
                                  (item) => item.title === "X"
                                ).social_link
                              }
                            >
                              <i className="fa fa-twitter" aria-hidden="true" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                footerDetails?.social_list?.find(
                                  (item) => item.title === "Linkedin"
                                ).social_link
                              }
                            >
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                footerDetails?.social_list?.find(
                                  (item) => item.title === "Pinterest"
                                ).social_link
                              }
                            >
                              <i
                                className="fa fa-pinterest-p"
                                aria-hidden="true"
                              />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                footerDetails?.social_list?.find(
                                  (item) => item.title === "Youtube"
                                ).social_link
                              }
                            >
                              <i
                                className="fa fa-youtube-play"
                                aria-hidden="true"
                              />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

          {/* footer links  */}
              <div id="data-university" className="row other_links mb-5">
                <div className="col-lg-12 mb-3">
                  <div className="footer_text">
                    <h5 className="text-white">
                      Online and Distance Best College for 
                    </h5>
                  </div>
                </div>
                <div className="col-lg-12">
                  <ul className="citiess">
                    {footerDet?.is_cities?.map((item, index) => (
                      <li key={index}>
                        <Link to={`/onlinemba/${item.name}`}>{item.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div id="data_courses" className="row other_links mb-5">
                <div className="col-lg-12 mb-3">
                  <div className="footer_text">
                    <h5 className="text-white">
                      Top University for Online MBA In
                    </h5>
                  </div>
                </div>
                <div className="col-lg-12">
                  <ul className="citiess">
                    {footerDet?.is_course?.map((item, index) => (
                      <li key={index}>
                        <Link to={`/onlinemba/${item.name}`} key={index}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div id="data_specialization" className="row other_links mb-5">
                <div className="col-lg-12 mb-3">
                  <div className="footer_text">
                    <h5 className="text-white">
                      Best Online | Distance University for Specialization in
                    </h5>
                  </div>
                </div>
                <div className="col-lg-12">
                  <ul className="citiess">
                    {footerDet?.is_specialization?.map((item, index) => (
                      <li key={index}>
                        <Link to={`/onlinemba/${item.name}`} key={index}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                    {/* <li>
                      <Link to>
                        Online University For Mba in Fianance In Maharshtra
                      </Link>
                    </li>
                    <li>
                      <Link to>
                        Online University For Mba in Marketing In Pune
                      </Link>
                    </li>
                    <li>
                      <Link to>
                        Distance University For Hr Management In Delhi
                      </Link>
                    </li>
                    <li>
                      <Link to>Distance MBA Chennai</Link>
                    </li>
                    <li>
                      <Link to>
                        Best University for Business Analytics In Bangalore
                      </Link>
                    </li>
                    <li>
                      <Link to>
                        Distance College For Operations Management in Hyderabad
                      </Link>
                    </li>
                    <li>
                      <Link to>
                        Online University For General Management In Jaipur
                      </Link>
                    </li>
                    <li>
                      <Link to>Distance MBA Chennai</Link>
                    </li>
                    <li>
                      <Link to>
                        Best University for Business Analytics In Bangalore
                      </Link>
                    </li>
                    <li>
                      <Link to>
                        Distance College For Operations Management in Hyderabad
                      </Link>
                    </li>
                    <li>
                      <Link to>
                        Online University For General Management In Jaipur
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* footer links End */}
            </div>
          </div>
          <div className="row copyright_row">
            <hr />
            <div className="disclaim-cntent col-lg-12 mb-5">
              <p>
                University Guroo provides unbiased, accurate information for
                university admissions and programs. The website and its contents
                are for informational purposes only and do not replace the
                offerings and resources provided by the university's academic
                partners. University Guroo does not engage in intellectual
                property infringement and does not make representations or
                warranties regarding the accuracy, adequacy, validity,
                reliability, or completeness of the information. Users should
                independently verify and rely on the offerings and resources
                provided by their respective universities and academic partners.
                The information provided by University Guroo on
                www.universityguroo.com or any of its mobile or other
                applications is strictly for general informational purposes. All
                information on the website and our mobile application is
                provided in good faith, accurately, and to the best of our
                knowledge; however, we make no representations or warranties of
                any kind, express or implied, regarding the accuracy, adequacy,
                validity, reliability, or completeness of any information on the
                website or our mobile application. University Guroo and its
                fraternity disclaim all responsibility for any mistakes or
                omissions, as well as any losses or damages that may arise from
                the use of this information.
              </p>
            </div>
            <div className="col">
              <div className="copyright d-flex flex-lg-row flex-column align-items-center justify-content-start">
                <div className="cr_text">
                  {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                  Copyright © . All rights reserved by
                  <Link to="universityguroo.com">University Guroo.</Link>
                  {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                </div>
                <div className="ml-lg-auto cr_links">
                  <ul className="cr_list">
                    <li>
                      <Link to="#">Copyright notification</Link>
                    </li>
                    <li>
                      <Link to="#">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
